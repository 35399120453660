define("ember-modal-dialog/templates/components/tether-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.hasOverlay}}
    <EmberWormhole @to={{this.destinationElementId}}>
      <div
        class={{this.overlayClassNamesString}}
        onclick={{action this.onClickOverlay}}
        tabindex='-1'
        data-emd-overlay
      >
      </div>
    </EmberWormhole>
  {{/if}}
  <EmberTether
    @class={{this.containerClassNamesString}}
    @target={{this.tetherTarget}}
    @attachment={{this.attachment}}
    @targetAttachment={{this.targetAttachment}}
    @targetModifier={{this.targetModifier}}
    @classPrefix={{this.tetherClassPrefix}}
    @offset={{this.offset}}
    @targetOffset={{this.targetOffset}}
    @constraints={{this.constraints}}
    ...attributes
  >
    {{yield}}
  </EmberTether>
  */
  {
    "id": "GNvV6Mw+",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"hasOverlay\"]]],null,{\"statements\":[[0,\"  \"],[5,\"ember-wormhole\",[],[[\"@to\"],[[23,0,[\"destinationElementId\"]]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[23,0,[\"overlayClassNamesString\"]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,0,[\"onClickOverlay\"]]],null]],[10,\"tabindex\",\"-1\"],[10,\"data-emd-overlay\",\"\"],[8],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[5,\"ember-tether\",[[13,1]],[[\"@class\",\"@target\",\"@attachment\",\"@targetAttachment\",\"@targetModifier\",\"@classPrefix\",\"@offset\",\"@targetOffset\",\"@constraints\"],[[23,0,[\"containerClassNamesString\"]],[23,0,[\"tetherTarget\"]],[23,0,[\"attachment\"]],[23,0,[\"targetAttachment\"]],[23,0,[\"targetModifier\"]],[23,0,[\"tetherClassPrefix\"]],[23,0,[\"offset\"]],[23,0,[\"targetOffset\"]],[23,0,[\"constraints\"]]]],{\"statements\":[[0,\"\\n  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/tether-dialog.hbs"
    }
  });
  _exports.default = _default;
});