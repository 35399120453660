define("ember-wormhole/templates/components/ember-wormhole", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0aWguNHX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"unbound\",[[23,0,[\"_wormholeHeadNode\"]]],null],false],[14,1],[1,[28,\"unbound\",[[23,0,[\"_wormholeTailNode\"]]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-wormhole/templates/components/ember-wormhole.hbs"
    }
  });
  _exports.default = _default;
});