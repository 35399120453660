define("@zestia/ember-simple-infinite-scroller/components/infinite-scroller", ["exports", "@zestia/ember-simple-infinite-scroller/templates/components/infinite-scroller"], function (_exports, _infiniteScroller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InfiniteScrollerComponent = (_dec = Ember.inject.service('-infinite-scroller'), (_class = (_temp = class InfiniteScrollerComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "_infiniteScroller", _descriptor, this);

      _defineProperty(this, "layout", _infiniteScroller.default);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "onLoadMore", null);

      _defineProperty(this, "selector", null);

      _defineProperty(this, "useDocument", false);

      _defineProperty(this, "scrollDebounce", 100);

      _defineProperty(this, "leeway", '0%');

      _defineProperty(this, "error", null);

      _defineProperty(this, "isLoading", false);

      _defineProperty(this, "isScrollable", false);

      _defineProperty(this, "domElement", null);
    }

    handleInsertElement(element) {
      this._registerElement(element);

      this._scheduleCheckScrollable();

      this._listen();
    }

    handleDestroyElement() {
      this._stopListening();

      this._deregisterElement();
    }

    loadMore() {
      this._loadMore();
    }

    _registerElement(element) {
      Ember.set(this, 'domElement', element);
    }

    _deregisterElement() {
      Ember.set(this, 'domElement', null);
    }

    _isScrollable() {
      let element = this._scroller();

      if (this.useDocument) {
        element = this._documentElement();
      }

      return element.scrollHeight > element.clientHeight;
    }

    _scheduleCheckScrollable() {
      Ember.run.scheduleOnce('afterRender', this, '_checkScrollable');
    }

    _checkScrollable() {
      Ember.set(this, 'isScrollable', this._isScrollable());
    }

    _listen() {
      this._scrollHandler = Ember.run.bind(this, '_scroll');

      this._listener().addEventListener('scroll', this._scrollHandler);
    }

    _stopListening() {
      this._listener().removeEventListener('scroll', this._scrollHandler);

      Ember.run.cancel(this._scrollDebounceId);
    }

    _scroll(e) {
      this._scrollDebounceId = Ember.run.debounce(this, '_debouncedScroll', e, this.scrollDebounce);
    }

    _debouncedScroll() {
      if (this._shouldLoadMore()) {
        this._loadMore();
      }
    }

    _log() {
      this._infiniteScroller.log(...arguments);
    }

    _leeway() {
      return parseInt(this.leeway, 10);
    }

    _document() {
      return this._infiniteScroller.document;
    }

    _documentElement() {
      return this._infiniteScroller.documentElement;
    }

    _listener() {
      if (this.useDocument) {
        return this._document();
      } else {
        return this._scroller();
      }
    }

    _scroller() {
      if (this.selector) {
        return this.domElement.querySelector(this.selector);
      } else {
        return this.domElement;
      }
    }

    _shouldLoadMore() {
      let state;

      if (this.useDocument) {
        state = this._detectBottomOfElementInDocument();
      } else {
        state = this._detectBottomOfElement();
      }

      state.shouldLoadMore = state.reachedBottom && !this.isLoading;

      this._log(state);

      return state.shouldLoadMore;
    }

    _detectBottomOfElementInDocument() {
      const clientHeight = this._infiniteScroller.documentElement.clientHeight;

      const bottom = this._scroller().getBoundingClientRect().bottom;

      const leeway = this._leeway();

      const pixelsToBottom = bottom - clientHeight;
      const percentageToBottom = pixelsToBottom / bottom * 100;
      const reachedBottom = percentageToBottom <= leeway;
      return {
        clientHeight,
        bottom,
        leeway,
        pixelsToBottom,
        percentageToBottom,
        reachedBottom
      };
    }

    _detectBottomOfElement() {
      const scrollHeight = this._scroller().scrollHeight;

      const scrollTop = this._scroller().scrollTop;

      const clientHeight = this._scroller().clientHeight;

      const bottom = scrollHeight - clientHeight;

      const leeway = this._leeway();

      const pixelsToBottom = bottom - scrollTop;
      const percentageToBottom = pixelsToBottom / bottom * 100;
      const reachedBottom = percentageToBottom <= leeway;
      return {
        scrollHeight,
        scrollTop,
        clientHeight,
        bottom,
        leeway,
        pixelsToBottom,
        percentageToBottom,
        reachedBottom
      };
    }

    _loadMore() {
      Ember.set(this, 'error', null);
      Ember.set(this, 'isLoading', true);
      Ember.RSVP.resolve(this.onLoadMore()).catch(Ember.run.bind(this, '_loadError')).finally(Ember.run.bind(this, '_loadFinished'));
    }

    _loadError(error) {
      if (this.isDestroyed) {
        return;
      }

      Ember.set(this, 'error', error);
    }

    _loadFinished() {
      if (this.isDestroyed) {
        return;
      }

      Ember.set(this, 'isLoading', false);

      this._scheduleCheckScrollable();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_infiniteScroller", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleInsertElement", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyElement", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype)), _class));
  _exports.default = InfiniteScrollerComponent;
});