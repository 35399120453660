define("ember-table/components/-private/scroll-indicators/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pyPtcmSf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"showLeft\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"data-test-ember-table-scroll-indicator\",\"left\"],[10,\"class\",\"scroll-indicator scroll-indicator__left\"],[11,\"style\",[23,0,[\"leftStyle\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"showRight\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"data-test-ember-table-scroll-indicator\",\"right\"],[10,\"class\",\"scroll-indicator scroll-indicator__right\"],[11,\"style\",[23,0,[\"rightStyle\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"showTop\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"data-test-ember-table-scroll-indicator\",\"top\"],[10,\"class\",\"scroll-indicator scroll-indicator__top\"],[11,\"style\",[23,0,[\"topStyle\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"showBottom\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"data-test-ember-table-scroll-indicator\",\"bottom\"],[10,\"class\",\"scroll-indicator scroll-indicator__bottom\"],[11,\"style\",[23,0,[\"bottomStyle\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/-private/scroll-indicators/template.hbs"
    }
  });

  _exports.default = _default;
});