define("ember-modifier/-private/class/modifier-manager", ["exports", "ember-modifier/-private/class/modifier"], function (_exports, _modifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ClassBasedModifierManager {
    constructor() {
      _defineProperty(this, "capabilities", Ember._modifierManagerCapabilities('3.13'));
    }

    createModifier(factory, args) {
      let {
        owner,
        class: modifier
      } = factory;
      return new modifier(owner, args);
    }

    installModifier(instance, element) {
      instance.element = element;
      instance.didReceiveArguments();
      instance.didInstall();
    }

    updateModifier(instance, args) {
      // TODO: this should be an args proxy
      Ember.set(instance, 'args', args);
      instance.didUpdateArguments();
      instance.didReceiveArguments();
    }

    destroyModifier(instance) {
      instance.willRemove();
      instance.element = null;

      if (instance[_modifier.DESTROYING]) {
        return;
      }

      let meta = Ember.meta(instance);
      meta.setSourceDestroying();
      instance[_modifier.DESTROYING] = true;
      Ember.run.schedule('actions', instance, instance.willDestroy);
      Ember.run.schedule('destroy', undefined, scheduleDestroy, instance, meta);
    }

  }

  function scheduleDestroy(modifier, meta) {
    if (modifier[_modifier.DESTROYED]) {
      return;
    }

    Ember.destroy(modifier);
    meta.setSourceDestroyed();
    modifier[_modifier.DESTROYED] = true;
  }

  var _default = new ClassBasedModifierManager();

  _exports.default = _default;
});