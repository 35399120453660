define("ember-modal-dialog/templates/components/in-place-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div
    class={{concat
      this.containerClassNamesString
      ' '
      this.attachmentClass
      ' '
      this.containerClass
    }}
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "lpMegb0T",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[28,\"concat\",[[23,0,[\"containerClassNamesString\"]],\" \",[23,0,[\"attachmentClass\"]],\" \",[23,0,[\"containerClass\"]]],null]],[13,1],[8],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/in-place-dialog.hbs"
    }
  });
  _exports.default = _default;
});