define("@zestia/ember-simple-infinite-scroller/templates/components/infinite-scroller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JaQtYFGU",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[28,\"concat\",[\"infinite-scroller\",[28,\"if\",[[23,0,[\"isLoading\"]],\" infinite-scroller--loading\"],null],[28,\"if\",[[23,0,[\"isScrollable\"]],\" infinite-scroller--scrollable\"],null]],null]],[13,1],[3,\"did-insert\",[[23,0,[\"handleInsertElement\"]]]],[3,\"will-destroy\",[[23,0,[\"handleDestroyElement\"]]]],[8],[0,\"\\n  \"],[14,2,[[28,\"hash\",null,[[\"error\",\"isScrollable\",\"isLoading\",\"loadMore\"],[[23,0,[\"error\"]],[23,0,[\"isScrollable\"]],[23,0,[\"isLoading\"]],[23,0,[\"loadMore\"]]]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@zestia/ember-simple-infinite-scroller/templates/components/infinite-scroller.hbs"
    }
  });

  _exports.default = _default;
});