define("ember-modal-dialog/templates/components/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <this.whichModalDialogComponent
    @wrapperClass={{this.wrapperClass}}
    @wrapperClassNames={{this.wrapperClassNames}}
    @overlayClass={{this.overlayClass}}
    @overlayClassNames={{this.overlayClassNames}}
    @containerClass={{this.containerClass}}
    @containerClassNames={{this.containerClassNames}}
    @hasOverlay={{this.hasOverlay}}
    @translucentOverlay={{this.translucentOverlay}}
    @clickOutsideToClose={{this.clickOutsideToClose}}
    @destinationElementId={{this.destinationElementId}}
    @overlayPosition={{this.overlayPosition}}
    @tetherTarget={{this.tetherTarget}}
    @legacyTarget={{this.target}}
    @attachment={{this.attachment}}
    @targetAttachment={{this.targetAttachment}}
    @targetModifier={{this.targetModifier}}
    @targetOffset={{this.targetOffset}}
    @offset={{this.offset}}
    @tetherClassPrefix={{this.tetherClassPrefix}}
    @constraints={{this.constraints}}
    @attachmentClass={{this.attachmentClass}}
    @stack={{this.stack}}
    @value={{this.value}}
    @onClickOverlay={{this.onClickOverlayAction}}
    @onClose={{this.onCloseAction}}
    ...attributes
  >
    {{yield}}
  </this.whichModalDialogComponent>
  */
  {
    "id": "IgSSbhcQ",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[6,[23,0,[\"whichModalDialogComponent\"]],[[13,1]],[[\"@wrapperClass\",\"@wrapperClassNames\",\"@overlayClass\",\"@overlayClassNames\",\"@containerClass\",\"@containerClassNames\",\"@hasOverlay\",\"@translucentOverlay\",\"@clickOutsideToClose\",\"@destinationElementId\",\"@overlayPosition\",\"@tetherTarget\",\"@legacyTarget\",\"@attachment\",\"@targetAttachment\",\"@targetModifier\",\"@targetOffset\",\"@offset\",\"@tetherClassPrefix\",\"@constraints\",\"@attachmentClass\",\"@stack\",\"@value\",\"@onClickOverlay\",\"@onClose\"],[[23,0,[\"wrapperClass\"]],[23,0,[\"wrapperClassNames\"]],[23,0,[\"overlayClass\"]],[23,0,[\"overlayClassNames\"]],[23,0,[\"containerClass\"]],[23,0,[\"containerClassNames\"]],[23,0,[\"hasOverlay\"]],[23,0,[\"translucentOverlay\"]],[23,0,[\"clickOutsideToClose\"]],[23,0,[\"destinationElementId\"]],[23,0,[\"overlayPosition\"]],[23,0,[\"tetherTarget\"]],[23,0,[\"target\"]],[23,0,[\"attachment\"]],[23,0,[\"targetAttachment\"]],[23,0,[\"targetModifier\"]],[23,0,[\"targetOffset\"]],[23,0,[\"offset\"]],[23,0,[\"tetherClassPrefix\"]],[23,0,[\"constraints\"]],[23,0,[\"attachmentClass\"]],[23,0,[\"stack\"]],[23,0,[\"value\"]],[23,0,[\"onClickOverlayAction\"]],[23,0,[\"onCloseAction\"]]]],{\"statements\":[[0,\"\\n  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/modal-dialog.hbs"
    }
  });
  _exports.default = _default;
});