define("ember-table/-private/utils/observer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeObserver = _exports.addObserver = _exports.observer = void 0;
  const USE_ASYNC_OBSERVERS = true;

  function asyncObserver(...args) {
    let fn = args.pop();
    let dependentKeys = args;
    let sync = false;
    return Ember.observer({
      dependentKeys,
      fn,
      sync
    });
  }

  function asyncAddObserver(...args) {
    let obj, path, target, method;
    let sync = false;
    obj = args[0];
    path = args[1];
    (true && !(args.length === 3 || args.length === 4) && Ember.assert(`Expected 3 or 4 args for addObserver, got ${args.length}`, args.length === 3 || args.length === 4));

    if (args.length === 3) {
      target = null;
      method = args[2];
    } else if (args.length === 4) {
      target = args[2];
      method = args[3];
    }

    return Ember.addObserver(obj, path, target, method, sync);
  }

  function asyncRemoveObserver(...args) {
    let obj, path, target, method;
    let sync = false;
    obj = args[0];
    path = args[1];
    (true && !(args.length === 3 || args.length === 4) && Ember.assert(`Expected 3 or 4 args for addObserver, got ${args.length}`, args.length === 3 || args.length === 4));

    if (args.length === 3) {
      target = null;
      method = args[2];
    } else {
      target = args[2];
      method = args[3];
    }

    return Ember.removeObserver(obj, path, target, method, sync);
  }

  const observer = USE_ASYNC_OBSERVERS ? asyncObserver : Ember.observer;
  _exports.observer = observer;
  const addObserver = USE_ASYNC_OBSERVERS ? asyncAddObserver : Ember.addObserver;
  _exports.addObserver = addObserver;
  const removeObserver = Ember.removeObserver ? asyncRemoveObserver : Ember.removeObserver;
  _exports.removeObserver = removeObserver;
});