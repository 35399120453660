define("@zestia/ember-simple-infinite-scroller/services/-infinite-scroller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class InfiniteScrollerService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "isFastBoot", typeof FastBoot !== 'undefined');

      _defineProperty(this, "debug", false);

      _defineProperty(this, "_log", []);
    }

    init() {
      super.init(...arguments);

      if (!this.isFastBoot) {
        this.document = document;
        this.documentElement = document.documentElement;
      }
    }

    log(state) {
      if (this.debug) {
        this._log.push(state); // eslint-disable-next-line


        console.table([state]);
      }
    }

  }

  _exports.default = InfiniteScrollerService;
});