define("ember-table/components/ember-tbody/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3nyoeTX/",
    "block": "{\"symbols\":[\"rowValue\",\"api\",\"&inverse\",\"&default\"],\"statements\":[[4,\"vertical-collection\",[[23,0,[\"wrappedRows\"]]],[[\"containerSelector\",\"estimateHeight\",\"key\",\"staticHeight\",\"bufferSize\",\"renderAll\",\"firstReached\",\"lastReached\",\"firstVisibleChanged\",\"lastVisibleChanged\",\"idForFirstItem\"],[[23,0,[\"_containerSelector\"]],[23,0,[\"estimateRowHeight\"]],[23,0,[\"key\"]],[23,0,[\"staticHeight\"]],[23,0,[\"bufferSize\"]],[23,0,[\"renderAll\"]],[23,0,[\"firstReached\"]],[23,0,[\"lastReached\"]],[23,0,[\"firstVisibleChanged\"]],[23,0,[\"lastVisibleChanged\"]],[23,0,[\"idForFirstItem\"]]]],{\"statements\":[[4,\"-ember-table-private/row-wrapper\",null,[[\"rowValue\",\"columns\",\"columnMetaCache\",\"rowMetaCache\",\"canSelect\",\"checkboxSelectionMode\",\"rowSelectionMode\",\"rowToggleMode\",\"rowsCount\"],[[23,1,[]],[23,0,[\"columns\"]],[23,0,[\"columnMetaCache\"]],[23,0,[\"rowMetaCache\"]],[23,0,[\"canSelect\"]],[23,0,[\"checkboxSelectionMode\"]],[23,0,[\"rowSelectionMode\"]],[23,0,[\"rowToggleMode\"]],[23,0,[\"wrappedRows\",\"length\"]]]],{\"statements\":[[4,\"if\",[[25,4]],null,{\"statements\":[[0,\"      \"],[14,4,[[28,\"hash\",null,[[\"rowValue\",\"rowMeta\",\"cells\",\"rowSelectionMode\",\"rowToggleMode\",\"rowsCount\",\"row\"],[[23,2,[\"rowValue\"]],[23,2,[\"rowMeta\"]],[23,2,[\"cells\"]],[23,2,[\"rowSelectionMode\"]],[23,2,[\"rowToggleMode\"]],[23,2,[\"rowsCount\"]],[28,\"component\",[\"ember-tr\"],[[\"api\"],[[23,2,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"ember-tr\",null,[[\"api\"],[[23,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[14,3],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-tbody/template.hbs"
    }
  });

  _exports.default = _default;
});