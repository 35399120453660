define("ember-modifier/-private/class/modifier", ["exports", "ember-modifier/-private/class/modifier-manager", "ember-modifier/-private/utils/symbol"], function (_exports, _modifierManager, _symbol) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DESTROYED = _exports.DESTROYING = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const DESTROYING = (0, _symbol.symbol)('destroying');
  _exports.DESTROYING = DESTROYING;
  const DESTROYED = (0, _symbol.symbol)('destroyed');
  _exports.DESTROYED = DESTROYED;

  class ClassBasedModifier {
    constructor(owner, args) {
      _defineProperty(this, DESTROYING, false);

      _defineProperty(this, DESTROYED, false);

      Ember.setOwner(this, owner);
      this.element = null;
      this.args = args;
    }

    didReceiveArguments() {}

    didUpdateArguments() {}

    didInstall() {}

    willRemove() {}

    willDestroy() {}

    get isDestroying() {
      return this[DESTROYING];
    }

    get isDestroyed() {
      return this[DESTROYED];
    }

  }

  _exports.default = ClassBasedModifier;
  Ember._setModifierManager(() => _modifierManager.default, ClassBasedModifier);
});