define("ember-table/components/ember-th/sort-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "snslX1S1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"isSorted\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"data-test-sort-indicator\",\"\"],[11,\"class\",[29,[\"et-sort-indicator \",[28,\"if\",[[23,0,[\"isSortedAsc\"]],\"is-ascending\",\"is-descending\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[23,0,[\"columnMeta\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"isMultiSorted\"]]],null,{\"statements\":[[0,\"        \"],[1,[23,0,[\"sortIndex\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isSortable\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"data-test-sort-toggle\",\"\"],[10,\"class\",\"et-sort-toggle et-speech-only\"],[8],[0,\"Toggle Sort\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-th/sort-indicator/template.hbs"
    }
  });

  _exports.default = _default;
});