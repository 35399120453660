define('ember-scrollable/services/scrollbar-thickness', ['exports', 'ember-scrollable/util/measurements'], function (exports, _measurements) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    thickness: Ember.computed(() => {
      let tempEl = document.createElement('div');
      tempEl.setAttribute('style', 'width: 50px; position: "absolute"; left: -100px;');
      tempEl.classList.add('scrollbar-width-tester');
      tempEl.innerHTML = `<div style="overflow: scroll;"><div class="scrollbar-width-tester__inner"></div></div>`;
      document.body.appendChild(tempEl);

      let width = (0, _measurements.getWidth)(tempEl);
      let widthMinusScrollbars = (0, _measurements.getWidth)(tempEl.querySelector('.scrollbar-width-tester__inner'));

      return width - widthMinusScrollbars;
    })
  });
});